import React, { useEffect, useState } from "react";
import AuthenticationWizardStep from "../../AuthenticationWizardStep";
import LoadingButton from "@mui/lab/LoadingButton";
import { Alert, Box, CircularProgress, FormControl } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setAuthWizardStep } from "../../../../actions/appActions";
import { AUTH_BY_EMAIL_STEP_LOGIN_FORM } from "../../constants";
import { selectAuthWizardForm } from "../../../../selectors/appSelectors";
import axios from "../../../../api/axios";
import { getAuthorization } from "../../../../utils/auth";

const AuthByEmailChangeEmailExecutionForm = () => {
  const dispatch = useDispatch();
  const form = useSelector(selectAuthWizardForm);

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const handleExecution = (token) => {
    setLoading(true);
    axios
      .post(process.env.REACT_APP_SERVER_URL + "/change-email/execute", { token }, {
        headers: {
          Authorization: getAuthorization(),
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setSubmitted(true);
        }
      })
      .catch((error) => {
        setError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  useEffect(() => {
    if (!!form && !!form.changeEmailToken) {
      handleExecution(form.changeEmailToken);
    }
  }, [form])

  if (loading) {
    return (
      <>
        <Box sx={{ textAlign: "center" }}>
          <CircularProgress color="inherit" />
        </Box>
      </>
    );
  }

  return (
    <AuthenticationWizardStep
      title={"Změna e-mailové adresy"}
      subtitle={""}
    >
      {!!submitted && !error && <><Alert severity={'success'}>E-mailová adresa byla změněna. Nyní se můžete přihlásit novou e-mailovou adresou.</Alert><FormControl fullWidth margin="normal">
        <LoadingButton
          disabled={loading}
          loading={loading}
          size={"large"}
          variant={"contained"}
          onClick={() => {
            dispatch(setAuthWizardStep(AUTH_BY_EMAIL_STEP_LOGIN_FORM));
          }}
        >
          Přihlásit se
        </LoadingButton>
      </FormControl></>}
      {!!submitted && !!error && <><Alert severity={'error'}>E-mailová adresa nebyla změněna. Přihlaste se svojí původní adresou a zkuste to znovu.</Alert><FormControl fullWidth margin="normal">
        <LoadingButton
          disabled={loading}
          loading={loading}
          size={"large"}
          variant={"contained"}
          onClick={() => {
            dispatch(setAuthWizardStep(AUTH_BY_EMAIL_STEP_LOGIN_FORM));
          }}
        >
          Přihlásit se
        </LoadingButton>
      </FormControl></>}

    </AuthenticationWizardStep>
  );
};

export default AuthByEmailChangeEmailExecutionForm;
