import React from "react";
import { Card, CardContent } from "@mui/material";

const LayoutUnsecured = (props) => {
  const { children } = props;

  return (
    <Card
      variant={"outlined"}
      id="loyalty-app-unsecured"
    >
      <CardContent
        sx={{
          p: {
            md: 6
          },
          "&:last-child": {
            p: {
              md: 6
            },
          }
        }}>
        {children}
      </CardContent>
    </Card>
  )
};

export default LayoutUnsecured;
