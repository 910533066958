import React from "react";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText, useTheme,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  selectFormFieldError,
  selectProfileFormField, selectView,
  selectBrand
} from "../../selectors/appSelectors";
import { setProfileFormField } from "../../actions/appActions";

const ProfileCheckTermField = (props) => {
  const { label, name, disabled = false } = props;

  const brand = useSelector(selectBrand);

  const dispatch = useDispatch();

  const value = useSelector((state) => selectProfileFormField(state, { name }));
  const error = useSelector((state) => selectFormFieldError(state, { name }));
  const theme = useTheme();
  // const required = useSelector((state) =>
  //   selectFormFieldIsRequired(state, { name })
  // );

  const handleChange = (event) => {
    dispatch(setProfileFormField({ name, value: event.target.checked }));
  };
  return (
    <Box sx={{ display: "flex" }}>
      <FormControl error={error} margin="none">
        <FormControlLabel
          control={<Checkbox checked={value} onChange={handleChange} disabled={disabled} />}
          label={label}
        />
        {!!error && <FormHelperText>{error}</FormHelperText>}
      </FormControl>
      <Box sx={{ mt: 1 }}>
        {name === 'checkVOP' && <>(
          <Box
            component={'a'}
            sx={{ color: theme.palette.primary.main, textDecoration: 'none' }}
            href={brand.vopHref}
            target={"_blank"}>
            Obchodní podmínky
          </Box>
          )</>
        }
      </Box>
    </Box>
  );
};

const ProfileCheckTermsForm = () => {
  const view = useSelector((state) => selectView(state));
  return (
    <>
      <Box sx={{ mt: 1 }}>
        <ProfileCheckTermField
          name={"checkVOP"}
          label={"Souhlasím s obchodními podmínkami"}
          disabled={view === '/profil'}
        />
        <ProfileCheckTermField
          name={"checkNewsletter"}
          label={"Souhlasím se zasíláním newsletteru"}
        />
      </Box>
    </>
  );
};

export default ProfileCheckTermsForm;
