import React from "react";
import AuthenticationWizardStep from "../../AuthenticationWizardStep";
import LoadingButton from "@mui/lab/LoadingButton";
import { FormControl } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { selectAuthWizardLoading } from "../../../../selectors/appSelectors";
import {
  resetAuthWizard,
  setAuthWizardStep,
} from "../../../../actions/appActions";
import { AUTH_BY_EMAIL_STEP_LOGIN_FORM } from "../../constants";

const AuthByEmailForgottenPasswordResetedForm = () => {
  const dispatch = useDispatch();
  const loading = useSelector(selectAuthWizardLoading);

  React.useEffect(() => {
    const newUrl = window.location.href.replace(window.location.search, "");
    window.history.pushState({}, null, newUrl);
  }, []);

  return (
    <AuthenticationWizardStep
      title={"Nové heslo"}
      subtitle={"Heslo bylo změněno, nyní se můžete přihlásit."}
    >
      <FormControl fullWidth margin="normal">
        <LoadingButton
          disabled={loading}
          loading={loading}
          size={"large"}
          variant={"contained"}
          onClick={() => {
            dispatch(resetAuthWizard());
            dispatch(setAuthWizardStep(AUTH_BY_EMAIL_STEP_LOGIN_FORM));
          }}
        >
          Přihlásit se
        </LoadingButton>
      </FormControl>
    </AuthenticationWizardStep>
  );
};

export default AuthByEmailForgottenPasswordResetedForm;
