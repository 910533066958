import React from "react";
import AuthenticationWizardStep from "../../AuthenticationWizardStep";
import LoadingButton from "@mui/lab/LoadingButton";
import { FormControl } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { selectAuthWizardLoading } from "../../../../selectors/appSelectors";
import { setAuthWizardStep } from "../../../../actions/appActions";
import { AUTH_BY_POS_START } from "../../constants";

const AuthByEmailForgottenPasswordThankYouForm = () => {
  const dispatch = useDispatch();
  const loading = useSelector(selectAuthWizardLoading);

  return (
    <AuthenticationWizardStep
      title={"Zapomenuté heslo"}
      subtitle={
        "Na e-mail zákazníka byly odeslány pokyny k obnovení zapomenutého hesla. Heslo si zákazník může obnovit/změnit přes svůj e-mail."
      }
    >
      <FormControl fullWidth margin="normal">
        <LoadingButton
          disabled={loading}
          loading={loading}
          sx={{ mt: 4 }}
          size={"large"}
          variant={"contained"}
          onClick={() => {
            dispatch(setAuthWizardStep(AUTH_BY_POS_START));
          }}
        >
          Zpět na úvodní stranu
        </LoadingButton>
      </FormControl>
    </AuthenticationWizardStep>
  );
};

export default AuthByEmailForgottenPasswordThankYouForm;
