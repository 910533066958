import React from "react";
import { useDispatch } from "react-redux";
import { setView } from "../actions/appActions";

const useNavigate = () => {
  const dispatch = useDispatch();

  return (path) => {
    dispatch(setView(path));
  };
};

export default useNavigate;
