import {
  Button,
  FormControl,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import useNavigate from "../../../../hooks/useNavigate";
import { useDispatch, useSelector } from "react-redux";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  selectAuthWizardForm,
  selectAuthWizardLoading,
  selectFormErrors,
} from "../../../../selectors/appSelectors";
import AuthenticationWizardStep from "../../AuthenticationWizardStep";
import {
  handleChangeAuthWizardForm,
  handleKeyDown,
  handleSubmitAuthWizardForm,
} from "../../utils";
import { setAuthWizardStep } from "../../../../actions/appActions";
import {
  AUTH_BY_EMAIL_STEP_FORGOTTEN_PASSWORD_REQUEST_FORM,
  AUTH_BY_EMAIL_STEP_LOGIN_FORM,
} from "../../constants";

const AuthByEmailSigninForm = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const form = useSelector(selectAuthWizardForm);
  const loading = useSelector(selectAuthWizardLoading);
  const formErrors = useSelector(selectFormErrors);

  return (
    <AuthenticationWizardStep
      title={"Chci se registrovat!"}
      subtitle={"Registrace nového zákazníka"}
    >
      <TextField
        fullWidth
        margin="normal"
        id="email"
        label="E-mailová adresa *"
        name={"login"}
        value={form.login}
        onChange={handleChangeAuthWizardForm}
        onKeyPress={(event) => {
          handleKeyDown(event, { navigate });
        }}
        error={formErrors.hasOwnProperty("login")}
        helperText={
          formErrors.hasOwnProperty("login") ? formErrors["login"] : ""
        }
      />
      <FormControl fullWidth margin="normal">
        <LoadingButton
          disabled={loading}
          loading={loading}
          size={"large"}
          variant={"contained"}
          onClick={() => {
            handleSubmitAuthWizardForm({ navigate });
          }}
        >
          Registrovat se
        </LoadingButton>
      </FormControl>

      <Grid container textAlign={"center"} sx={{ mt: 4 }}>
        {formErrors.hasOwnProperty("login") && formErrors["login"] === "Uživatel s tímto e-mailem už existuje." ? (
          <>
            <Grid item xs={12} md={6} sx={{ mb: { xs: 4, md: 0 } }}>
              <Typography>Vypadá to, že účet u nás již máte</Typography>
              <Button
                sx={{ mt: 1 }}
                variant={"outlined"}
                color={"primary"}
                onClick={() => {
                  dispatch(
                    setAuthWizardStep(
                      AUTH_BY_EMAIL_STEP_FORGOTTEN_PASSWORD_REQUEST_FORM
                    )
                  );
                }}
              >
                Neznám své heslo
              </Button>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography>Nebo se rovnou přihlašte</Typography>
              <Button
                sx={{ mt: 1 }}
                variant={"outlined"}
                color={"primary"}
                onClick={() => {
                  dispatch(setAuthWizardStep(AUTH_BY_EMAIL_STEP_LOGIN_FORM));
                }}
              >
                Zpět na přihlášení
              </Button>
            </Grid>
          </>
        ) : (
          <>
            <Grid item xs={12}>
              <Button
                sx={{ mt: 1 }}
                variant={"outlined"}
                color={"primary"}
                onClick={() => {
                  dispatch(setAuthWizardStep(AUTH_BY_EMAIL_STEP_LOGIN_FORM));
                }}
              >
                Zpět na přihlášení
              </Button>
            </Grid>
          </>
        )}



      </Grid>
    </AuthenticationWizardStep>
  );
};

export default AuthByEmailSigninForm;
