export const LOCAL_STORAGE_AUTH_TOKEN_WEBSITE = "auth_token_website";
export const LOCAL_STORAGE_AUTH_TOKEN_USER = "auth_token_user";

export const hasLocalStorage = (key) => {
  return localStorage.hasOwnProperty(key);
};

export const setLocalStorage = (key, value) => {
  localStorage.setItem(key, value);
};

export const getLocalStorage = (key) => {
  return localStorage.getItem(key);
};

export const removeLocalStorage = (key) => {
  localStorage.removeItem(key);
};
