export const SET_WEBSITE = "SET_WEBSITE";
export const SET_VIEW = "SET_VIEW";
export const SET_AUTH_WIZARD = "SET_AUTH_WIZARD";
export const SET_POS_AUTH_WIZARD = "SET_POS_AUTH_WIZARD";
export const SET_FORM_ERRORS = "SET_FORM_ERRORS";
export const SET_PROFILE_FORM = "SET_PROFILE_FORM";
export const SET_PROFILE_FORM_FIELD = "SET_PROFILE_FORM_FIELD";

export const SET_PROFILE = "SET_PROFILE";

export const ADD_NOTIFICATION = "ADD_NOTIFICATION";
export const REMOVE_NOTIFICATION = "REMOVE_NOTIFICATION";

export const SET_RESPONSE = "SET_RESPONSE";
