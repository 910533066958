import React from "react";
import { Card, CardContent, Container, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { selectBrand } from "../selectors/appSelectors";

const Layout = (props) => {
  const { children } = props;
  const brand = useSelector(selectBrand);

  return (
    <>
      <Container>
        <Typography variant="h1" my={8}>
          {brand.texts.brand} Club
        </Typography>
        {children}
      </Container>

      <Typography
        variant="body2"
        textAlign={"center"}
        mt={2}
        sx={{
          fontSize: 13,
          opacity: .2
        }}
      >
        LTR Loyalty <code>{process.env.REACT_APP_VERSION}</code>
      </Typography>
    </>
  );
};
export default Layout;
