export const validCzechZIP = (zip) => {
    // Vytvoříme regulární výraz pro ověření formátu českého PSČ
    var regex = /^[1-7][0-9]{2}\s?[0-9]{2}$/;

    // Odstraníme nadbytečné mezery
    zip = zip.replace(/\s+/g, '');

    // Ověříme, zda ZIP odpovídá formátu českého PSČ
    if (regex.test(zip)) {
        return zip;
    } else {
        return '';
    }
}