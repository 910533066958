import React from "react";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Typography,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { setProfile } from "../../actions/appActions";
import axios from "../../api/axios";
import { getAuthorization } from "../../utils/auth";
import { useDispatch, useSelector } from "react-redux";
import { selectProfile } from "../../selectors/appSelectors";
import { Box } from '@mui/system';
import DeleteIcon from '@mui/icons-material/Delete';
import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrash';

const ProfileDeleteButton = (props) => {
  const { upsert } = props;

  const dispatch = useDispatch();
  const profile = useSelector(selectProfile);

  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const handleDelete = () => {
    setLoading(true);

    const data = {
      login: profile.login,
      userNumber: profile.userNumber,
      contacts: profile.contacts,
      options: profile.options,
      attributes: profile.attributes,
    };

    const attribute = {
      meaning: 0,
      name: "requestForciblyForgotten",
      value: "True",
    };

    const attributes = profile.hasOwnProperty("attributes")
      ? [...profile.attributes]
      : [];
    const index = attributes.findIndex((a) => {
      return a.name === attribute.name;
    });
    if (index > -1) {
      attributes[index] = attribute;
    } else {
      attributes.push(attribute);
    }

    data.attributes = attributes;

    upsert(
      data,
      () => {
        axios
          .get(process.env.REACT_APP_SERVER_URL + "/user/me", {
            headers: {
              Authorization: getAuthorization(),
            },
          })
          .then((response) => {
            dispatch(
              setProfile({
                profile: response.data,
              })
            );
            setOpen(false);
          })
          .catch((error) => {
            // logout(dispatch, navigate);
          });
      },
      () => { },
      () => {
        setLoading(false);
      }
    );
  };

  return (
    <>
      <Button
        sx={{ mt: 4 }}
        size={"small"}
        variant={"outlined"}
        onClick={() => {
          setOpen(true);
        }}
        startIcon={<DeleteIcon />}
      >
        Odstranit účet
      </Button>
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      >
        <DialogTitle>
          Opravdu si přejete odstranit svůj účet?
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Typography>
            Odcházíte? To nás mrzí! Vaše rozhodnutí chápeme, ale berte prosím na vědomí, že odstraněním účtu přijdete o všechny Vaše výhody, nasbírané body a již nebude možné čerpat žádné slevy. Není to škoda?
          </Typography>
          {loading && <Box sx={{ mt: 4, textAlign: "center" }}><CircularProgress /></Box>}
        </DialogContent>
        <Divider />
        {!loading && (
          <DialogActions>
            <LoadingButton
              variant={"outlined"}
              onClick={() => {
                handleDelete();
              }}
              startIcon={<DeleteIcon />}
            >
              Odstranit
            </LoadingButton>
            <Button onClick={() => setOpen(false)} variant={"contained"} startIcon={<RestoreFromTrashIcon />}>
              Neodstranit
            </Button>
          </DialogActions>
        )}
      </Dialog>
    </>
  );
};

export default ProfileDeleteButton;
