import {
  getLocalStorage,
  hasLocalStorage,
  LOCAL_STORAGE_AUTH_TOKEN_USER,
  LOCAL_STORAGE_AUTH_TOKEN_WEBSITE,
  removeLocalStorage,
} from "./localStorage";
import { resetAuthWizard } from "../actions/appActions";

export const getAuthorization = () => {
  if (hasLocalStorage(LOCAL_STORAGE_AUTH_TOKEN_USER)) {
    return "Bearer " + getLocalStorage(LOCAL_STORAGE_AUTH_TOKEN_USER);
  }

  return "Bearer " + getLocalStorage(LOCAL_STORAGE_AUTH_TOKEN_WEBSITE);
};

export const getAuthorizationByName = (name) => {
  if (hasLocalStorage(name)) {
    return "Bearer " + getLocalStorage(name);
  }

  return null;
};

export const logout = (dispatch, navigate) => {
  dispatch(resetAuthWizard());
  removeLocalStorage(LOCAL_STORAGE_AUTH_TOKEN_USER);
  navigate("/");
};
