import axios from "axios";
import store from "../store/store";
import { devLogRequest, devLogResponse } from "../dev/devActions";

const dispatch = store.dispatch;

// Add a request interceptor
axios.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    dispatch(devLogRequest(config));
    // console.log("request", config);
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
axios.interceptors.response.use(
  function (response) {
    // Do something with response data
    dispatch(devLogResponse(response));
    // console.log("response", response);
    return response;
  },
  function (error) {
    // Do something with response error
    dispatch(devLogResponse(error.response));
    return Promise.reject(error);
  }
);

export default axios;
