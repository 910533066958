import React from "react";
import { Box, Typography } from "@mui/material";
import { setFormErrors } from "../../actions/appActions";
import { useDispatch } from "react-redux";

const AuthenticationWizardStep = (props) => {
  const { title, subtitle, children, footer = null } = props;

  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(setFormErrors({}));
  }, []);

  return (
    <>
      <Typography variant={"h2"} mb={4}>
        {title}
      </Typography>
      <Typography variant={"h3"} mb={4}>
        {subtitle}
      </Typography>
      <Box>{children}</Box>
      {!!footer && <Box>{footer}</Box>}
    </>
  );
};

export default AuthenticationWizardStep;
