import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectAuthWizardLoading } from "../../../../selectors/appSelectors";
import { FormControl } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import AuthenticationWizardStep from "../../AuthenticationWizardStep";
import { setAuthWizardStep } from "../../../../actions/appActions";
import { AUTH_BY_POS_START } from "../../constants";

const AuthByPosVirtualCardRequested = () => {
  const dispatch = useDispatch();
  const loading = useSelector(selectAuthWizardLoading);

  return (
    <AuthenticationWizardStep
      title={"Vše hotovo"}
      subtitle={"Novou přidělenou kartu nalezne zákazník ve své zákaznické zóně."}
    >
      <FormControl fullWidth margin="normal">
        <LoadingButton
          disabled={loading}
          loading={loading}
          sx={{ mt: 4 }}
          size={"large"}
          variant={"contained"}
          onClick={() => {
            dispatch(setAuthWizardStep(AUTH_BY_POS_START));
          }}
        >
          Zpět na úvodní stranu
        </LoadingButton>
      </FormControl>
    </AuthenticationWizardStep>
  );
};

export default AuthByPosVirtualCardRequested;
