import { Button, FormControl, Grid, TextField } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import useNavigate from "../../../../hooks/useNavigate";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  selectAuthWizardForm,
  selectAuthWizardLoading,
  selectFormErrors,
} from "../../../../selectors/appSelectors";
import AuthenticationWizardStep from "../../AuthenticationWizardStep";
import {
  handleChangeAuthWizardForm,
  handleKeyDown,
  handleSubmitAuthWizardForm,
} from "../../utils";
import { setAuthWizardStep } from "../../../../actions/appActions";
import { AUTH_BY_EMAIL_STEP_LOGIN_FORM } from "../../constants";

const AuthByEmailForgottenPasswordRequestForm = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const form = useSelector(selectAuthWizardForm);
  const loading = useSelector(selectAuthWizardLoading);
  const formErrors = useSelector(selectFormErrors);

  return (
    <AuthenticationWizardStep
      title={"Zapomněli jste své heslo?"}
      subtitle={
        "Nevadí! Vyplňte svůj email a my Vám pošleme odkaz k vytvoření nového 😊."
      }
    >
      <TextField
        fullWidth
        margin="normal"
        autoFocus={true}
        label={"E-mail"}
        name={"login"}
        value={form.login}
        onChange={handleChangeAuthWizardForm}
        onKeyPress={(event) => {
          handleKeyDown(event, { navigate });
        }}
        error={formErrors.hasOwnProperty("login")}
        helperText={
          formErrors.hasOwnProperty("login") ? formErrors["login"] : ""
        }
      />
      <FormControl fullWidth margin="normal">
        <LoadingButton
          disabled={loading}
          loading={loading}
          size={"large"}
          variant={"contained"}
          onClick={() => {
            handleSubmitAuthWizardForm({ navigate });
          }}
        >
          Odeslat
        </LoadingButton>
      </FormControl>

      <Grid container textAlign={"center"} mt={2}>
        <Grid item md={12}>
          <Button
            sx={{ mt: 1 }}
            variant={"outlined"}
            color={"primary"}
            onClick={() => {
              dispatch(setAuthWizardStep(AUTH_BY_EMAIL_STEP_LOGIN_FORM));
            }}
          >
            Zpět na přihlášení
          </Button>
        </Grid>
      </Grid>
    </AuthenticationWizardStep>
  );
};

export default AuthByEmailForgottenPasswordRequestForm;
