import React from "react";
import {
  Box,
  Divider,
  Grid,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Typography,
} from "@mui/material";
import useNavigate from "../../hooks/useNavigate";
import axios from "../../api/axios";
import {
  getAuthorization,
  getAuthorizationByName,
  logout,
} from "../../utils/auth";
import { useDispatch, useSelector } from "react-redux";
import {
  CardGiftcard,
  CreditCard,
  Loyalty,
  Settings,
  ShoppingBasket,
} from "@mui/icons-material";
import LogoutIcon from "@mui/icons-material/Logout";
import { setProfile } from "../../actions/appActions";
import { selectProfile } from "../../selectors/appSelectors";
import MyProfile from "./pages/MyProfile";
import MyCards from "./pages/MyCards";
import MyPurchases from "./pages/MyPurchases";
import MyAccount from "./pages/MyAccount";
import MyVouchers from "./pages/MyVouchers";
import InfoDeleteAccount from "./infos/InfoDeleteAccount";
import { LOCAL_STORAGE_AUTH_TOKEN_WEBSITE } from "../../utils/localStorage";
import ProfileLoading from '../ProfileLoading/ProfileLoading';
import { validCzechZIP } from "../../utils/zip";

const profilePages = [
  {
    code: "myAccount",
    label: "Moje historie",
    icon: <Loyalty />,
    component: MyAccount,
  },
  {
    code: "myVouchers",
    label: "Moje poukazy",
    icon: <CardGiftcard />,
    component: MyVouchers,
  },
  {
    code: "myCards",
    label: "Moje karty",
    icon: <CreditCard />,
    component: MyCards,
  },
  // {
  //   code: "myPurchases",
  //   label: "Moje nákupy",
  //   icon: <ShoppingBasket />,
  //   component: MyPurchases,
  // },
  {
    code: "myProfile",
    label: "Nastavení účtu",
    icon: <Settings />,
    component: MyProfile,
  },
];

const Profile = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const profile = useSelector(selectProfile);

  const [selectedPageIndex, setSelectedPageIndex] = React.useState(0);

  const upsert = (data, onSuccess, onError, onFinally) => {
    if (!!data && !!data.contacts && !!data.contacts[0] && !!data.contacts[0].zip) {
      //PSČ může být pouze číselné a bez mezer
      data.contacts[0].zip = validCzechZIP(data.contacts[0].zip);
    }
    axios
      .request({
        method: "POST",
        url: process.env.REACT_APP_SERVER_URL + "/airme-proxy/PAUsers/upsert",
        data,
        headers: {
          Authorization: getAuthorizationByName(
            LOCAL_STORAGE_AUTH_TOKEN_WEBSITE
          ),
        },
      })
      .then((r) => {
        if (r.status === 200) {
          onSuccess();
        }
      })
      .catch((e) => {
        onError();
      })
      .finally(() => {
        onFinally();
      });
  };

  React.useEffect(() => {
    if (!!getAuthorization()) {
      axios
        .get(process.env.REACT_APP_SERVER_URL + "/user/me", {
          headers: {
            Authorization: getAuthorization(),
          },
        })
        .then((response) => {
          dispatch(
            setProfile({
              profile: response.data,
            })
          );
        })
        .catch((error) => {
          logout(dispatch, navigate);
        });
    }
  }, []);

  if (profile === null) {
    return <ProfileLoading />;
  }

  return (
    <>
      <Grid container spacing={6}>
        <Grid item xs={12} md={3}>
          <MenuList sx={{ pt: 0 }}>
            {profilePages.map((page, index) => {
              return (
                <MenuItem
                  variant="menu"
                  key={page.code}
                  selected={index === selectedPageIndex}
                  onClick={() => {
                    setSelectedPageIndex(index);
                  }}
                >
                  <ListItemIcon>{page.icon}</ListItemIcon>
                  <ListItemText disableTypography>{page.label}</ListItemText>
                </MenuItem>
              );
            })}
            <Divider />
            <MenuItem
              variant="menu"
              onClick={() => {
                logout(dispatch, navigate);
              }}
            >
              <ListItemIcon>
                <LogoutIcon />
              </ListItemIcon>
              <ListItemText disableTypography>Odhlásit se</ListItemText>
            </MenuItem>
          </MenuList>
        </Grid>

        <Grid item xs={12} md={9}>
          <InfoDeleteAccount upsert={upsert} />

          <Box>
            <Typography variant={"h2"} fontWeight={400} fontSize={30} mb={4}>
              {profilePages[selectedPageIndex].label}
            </Typography>
            {React.createElement(profilePages[selectedPageIndex].component, {
              upsert,
            })}
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default Profile;
