export const buildProfileAttributes = ({
  RegistredAtEshop = false,
  RegistredAtStore = false,
}) => {
  let dt;
  dt = new Date();

  const attributes = [];
  if (RegistredAtEshop) {
    attributes.push({
      meaning: 0,
      name: "RegistredAtEshopSince",
      value: dt.toISOString(),
    });
  }

  if (RegistredAtStore) {
    attributes.push({
      meaning: 0,
      name: "RegistredAtStoreSince",
      value: dt.toISOString(),
    });
  }

  return attributes;
};

export const buildProfileRewards = () => {
  let dt;
  dt = new Date();
  return [
    {
      //zákazník vždy při registraci nebo doregistraci se dostane do Věrnostního programu Basic
      Name: "Basic",
      Type: 0,
      Value: 0,
      LoyaltyLevel: 30,
      Created: dt.toISOString(),
    },
  ];
};

const buildProfileOption = (type, value) => {
  let dt, dt2;
  dt = new Date();
  dt2 = new Date();
  dt2.setFullYear(9999, 11, 31);

  if (value === 0 || !value) {
    return {
      Type: type,
      OptionTypeName: null,
      Value: 0,
      ValidFromUTC: dt.toISOString(),
      ValidToUtc: dt.toISOString(),
    };
  } else {
    return {
      Type: type,
      OptionTypeName: null,
      Value: 1,
      ValidFromUTC: dt.toISOString(),
      ValidToUTC: dt2.toISOString(),
    };
  }
};

export const buildProfileOptions = ({ profileForm = null }) => {
  const options = [];

  if (profileForm === null) {
    options.push(buildProfileOption(2, 1)); //Obchodní podmínky
    options.push(buildProfileOption(3, 1)); //Newsletter
  } else {
    options.push(buildProfileOption(2, profileForm.checkVOP));
    options.push(buildProfileOption(3, profileForm.checkNewsletter));
  }

  return options;
};

const buildBirthday = ({ profileForm }) => {
  if (!profileForm) {
    return "";
  }

  if (!profileForm.birthday) {
    return "";
  }

  if (typeof profileForm.birthday === "string") {
    const dt = new Date(profileForm.birthday);
    return dt.toISOString();
  }

  return profileForm.birthday.toISOString();
};

export const buildProfile = ({
  login = "",
  password = null,
  profileForm = null,
  rewards = null,
  options,
  attributes = null,
}) => {
  let dt, dt2;
  dt = new Date();
  dt2 = new Date();
  dt2.setFullYear(9999, 11, 31);

  const profile = {
    login: login,
    contacts: [
      {
        firstName: profileForm?.firstName || "",
        lastName: profileForm?.lastName || "",
        birthDay: buildBirthday({ profileForm }),
        gender: profileForm?.sex || "0",
        contactType: 2,
        isPrimary: true,
        vatPayer: false,
        addressLine1: !!profileForm
          ? [profileForm.addressStreet, profileForm.addressStreetNumber].join(
              " "
            )
          : "",
        addressType: 2,
        city: profileForm?.addressCity || "",
        zip: profileForm?.addressPostCode || "",
        countryIsocode: "CZ",
        email: login,
      },
    ],
    attributes: [],
    rewards: [],
    options: [],
  };

  if (!!password) {
    profile.password = password;
  }

  if (rewards) {
    profile.rewards = rewards;
  }

  if (options) {
    profile.options = options;
  }

  if (attributes) {
    profile.attributes = attributes;
  }

  return profile;
};

export const transformProfile2ProfileForm = ({ profile }) => {
  const contact = profile.contacts[0];
  const options = profile.options || [];

  const getAddress = (type) => {
    const lastIndex = contact.addressLine1.lastIndexOf(" ");
    if (lastIndex > -1) {
      switch (type) {
        case "addressStreet": {
          return contact.addressLine1.slice(0, lastIndex);
        }
        case "addressStreetNumber": {
          return contact.addressLine1.slice(lastIndex + 1);
        }
      }
    }

    return "";
  };

  const getOptionValueByType = (type) => {
    const index = options.findIndex((o) => {
      return o.type === type;
    });
    if (index > -1) {
      return options[index].value === 1;
    }
  };

  return {
    firstName: contact.firstName,
    lastName: contact.lastName,
    sex: contact.gender,
    birthday: contact.birthDay,
    addressStreet: getAddress("addressStreet"),
    addressStreetNumber: getAddress("addressStreetNumber"),
    addressCity: contact.city,
    addressPostCode: contact.zip,
    checkVOP: getOptionValueByType(2),
    checkNewsletter: getOptionValueByType(3),
  };
};

export const addAttributeFullyRegistred = (data, value) => {
  const copyAttributes = [...data.attributes];
  copyAttributes.push({
    meaning: 0,
    name: "fullyRegistred",
    value: value, //Musí být string
  });
  data.attributes = copyAttributes;
};
