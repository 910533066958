const addZeroFn = (t) => {
  return t < 10 ? "0" + t : t;
};

export const formatDate = (dtString) => {
  const dt = new Date(dtString);
  return [dt.getDate(), dt.getMonth() + 1, dt.getFullYear()]
    .map(addZeroFn)
    .join(".");
};

export const formatTime = (dtString) => {
  const dt = new Date(dtString);
  return [dt.getHours(), dt.getMinutes()].map(addZeroFn).join(":");
};

export const formatDateTime = (dtString) => {
  return formatDate(dtString) + " " + formatTime(dtString);
};
