import { configureStore } from "@reduxjs/toolkit";
import { appReducer } from "../reducers/appReducer";
import thunk from "redux-thunk";
import { logger } from "redux-logger/src";
import devReducer from "../dev/devReducer";

const middleware = [thunk];
if(process.env.REACT_APP_ENV === "dev"){
  middleware.push(logger);
}

const store = configureStore({
  reducer: {
    app: appReducer,
    dev: devReducer,
  },
  middleware: middleware,
});

export default store;
