import {
  Button,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useNavigate from "../../../../hooks/useNavigate";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  selectAuthWizardForm,
  selectAuthWizardLoading,
  selectFormErrors,
} from "../../../../selectors/appSelectors";
import AuthenticationWizardStep from "../../AuthenticationWizardStep";
import {
  handleChangeAuthWizardForm,
  handleKeyDown,
  handleSubmitAuthWizardForm,
} from "../../utils";
import { setAuthWizardStep } from "../../../../actions/appActions";
import {
  AUTH_BY_EMAIL_STEP_FORGOTTEN_PASSWORD_REQUEST_FORM,
  AUTH_BY_EMAIL_STEP_SIGNIN_FORM,
} from "../../constants";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

const AuthByEmailLoginForm = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const form = useSelector(selectAuthWizardForm);
  const loading = useSelector(selectAuthWizardLoading);
  const formErrors = useSelector(selectFormErrors);

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  return (
    <AuthenticationWizardStep
      title={"Vítejte!"}
      subtitle={"Přihlaste se pomocí svého e-mailu a hesla."}
    >
      <TextField
        fullWidth
        margin="normal"
        id="email"
        label="E-mailová adresa"
        // autoFocus={true}
        placeholder="@"
        name={"login"}
        value={form.login}
        onChange={handleChangeAuthWizardForm}
        onKeyPress={(event) => {
          handleKeyDown(event, null);
        }}
        error={formErrors.hasOwnProperty("login")}
        helperText={
          formErrors.hasOwnProperty("login") ? formErrors["login"] : ""
        }
      />
      <TextField
        fullWidth
        margin="normal"
        // autoFocus={true}
        type={showPassword ? "text" : "password"}
        label="Heslo"
        name={"password"}
        value={form.password}
        onChange={handleChangeAuthWizardForm}
        onKeyPress={(event) => {
          handleKeyDown(event, { navigate });
        }}
        error={formErrors.hasOwnProperty("password")}
        helperText={
          formErrors.hasOwnProperty("password") ? formErrors["password"] : ""
        }
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          )
        }}
      />
      <FormControl fullWidth margin="normal">
        <LoadingButton
          disabled={loading}
          loading={loading}
          size={"large"}
          variant={"contained"}
          onClick={() => {
            handleSubmitAuthWizardForm({ navigate });
          }}
        >
          Přihlásit se
        </LoadingButton>
      </FormControl>

      <Grid container textAlign={"center"} sx={{ mt: 4 }}>
        <Grid item xs={12} md={6} sx={{ mb: { xs: 4, md: 0 } }}>
          <Typography>Nemáte svůj účet?</Typography>
          <Button
            sx={{ mt: 1 }}
            variant={"outlined"}
            color={"primary"}
            onClick={() => {
              dispatch(setAuthWizardStep(AUTH_BY_EMAIL_STEP_SIGNIN_FORM));
            }}
          >
            Registrovat se
          </Button>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography>Zapomněli jste heslo?</Typography>
          <Button
            sx={{ mt: 1 }}
            variant={"outlined"}
            color={"primary"}
            onClick={() => {
              dispatch(
                setAuthWizardStep(
                  AUTH_BY_EMAIL_STEP_FORGOTTEN_PASSWORD_REQUEST_FORM
                )
              );
            }}
          >
            Neznám heslo
          </Button>
        </Grid>
      </Grid>
    </AuthenticationWizardStep>
  );
};

export default AuthByEmailLoginForm;
