import axios from "../../api/axios";
import { getAuthorizationByName } from "../../utils/auth";
import { LOCAL_STORAGE_AUTH_TOKEN_WEBSITE } from "../../utils/localStorage";

export const callApiForProfilePage = ({
  url,
  setLoading,
  onSuccess,
  onCatch = () => {},
  onFinally = () => {},
}) => {
  setLoading(true);
  axios
    .request({
      method: "GET",
      url: process.env.REACT_APP_SERVER_URL + url,
      data: {},
      headers: {
        Authorization: getAuthorizationByName(LOCAL_STORAGE_AUTH_TOKEN_WEBSITE),
      },
    })
    .then((r) => {
      onSuccess(r);
      // if (r.status === 200) {
      //   onSuccess()
      //   setCards(r.data[0]);
      // }
    })
    .catch((e) => {
      onCatch(e);
    })
    .finally(() => {
      setLoading(false);
      onFinally();
    });
};
