import HomeView from "../views/HomeView";
import LayoutUnsecured from "../layouts/LayoutUnsecured";
import ProfileView from "../views/ProfileView";
import LayoutSecured from "../layouts/LayoutSecured";
import PosView from "../views/PosView";

export const views = {
  "/": { component: HomeView, layout: LayoutUnsecured },
  "/pos": { component: PosView, layout: LayoutUnsecured },
  "/profil": { component: ProfileView, layout: LayoutSecured },
};
