import { DEV_LOG_REQUEST, DEV_LOG_RESPONSE } from "./devConstants";

export const devLogRequest = (request) => (dispatch) => {
  dispatch({
    type: DEV_LOG_REQUEST,
    payload: {
      request: request,
      created: new Date(),
    },
  });
};

export const devLogResponse = (response) => (dispatch) => {
  dispatch({
    type: DEV_LOG_RESPONSE,
    payload: {
      response: response,
      created: new Date(),
    },
  });
};
