import React from "react";
import {
  Box,
  CircularProgress,
} from "@mui/material";

export default function ProfileLoading() {
  return (
    <Box sx={{ textAlign: "center", my: 10 }}>
      <CircularProgress />
    </Box>
  );
};
