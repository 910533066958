export const buildUrlToDownloadCard = ({
  encryption,
  profile,
  walletType,
  mediaType,
  tenantId,
  cardId = null,
  voucherId = null,
}) => {
  const params = {
    TenantID: tenantId,
    returnRedirect: false,
    userNumber: encodeURIComponent(profile.userNumber),
  };

  if (!!cardId) {
    params.tagId = cardId;
  }
  if (!!voucherId) {
    params.voucherId = voucherId;
  }

  let path = "";
  if (encryption) {
  } else {
    switch (walletType) {
      case "GOOGLE":
        {
          path = `/PAUsers/googlePayObjectByUserNumber/${mediaType}`;
        }
        break;
      case "APPLE":
        {
          path = `/PAUsers/appleWalletByUserNumber/${mediaType}`;
        }
        break;
    }
  }

  const queryPath = Object.keys(params)
    .map((key) => {
      return key + "=" + params[key];
    })
    .join("&");

  return path + "?" + queryPath;
};
