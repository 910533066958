import React from "react";
import { useSelector } from "react-redux";
import {
  selectAuthWizardForm,
  selectAuthWizardLoading,
  selectFormErrors,
} from "../../../../selectors/appSelectors";
import { FormControl } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import AuthenticationWizardStep from "../../AuthenticationWizardStep";
import { handleSubmitAuthWizardForm } from "../../utils";
import TextFieldCardNumber from "./TextFieldCardNumber/TextFieldCardNumber";

const AuthByPosPhysicalCardRequest = () => {
  const form = useSelector(selectAuthWizardForm);
  const loading = useSelector(selectAuthWizardLoading);
  const formErrors = useSelector(selectFormErrors);

  return (
    <AuthenticationWizardStep
      title={"Naskenujte nebo napište číslo plastové karty pro zákazníka"}
      subtitle={""}
    >
      <TextFieldCardNumber />
      <FormControl fullWidth margin="normal">
        <LoadingButton
          disabled={loading}
          loading={loading}
          size={"large"}
          variant={"contained"}
          onClick={handleSubmitAuthWizardForm}
        >
          Pokračovat
        </LoadingButton>
      </FormControl>
    </AuthenticationWizardStep>
  );
};

export default AuthByPosPhysicalCardRequest;
