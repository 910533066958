import { FormControl, TextField, IconButton, InputAdornment } from "@mui/material";
import React, { useState } from "react";
import useNavigate from "../../../../hooks/useNavigate";
import { useSelector } from "react-redux";
import LoadingButton from "@mui/lab/LoadingButton";
import AuthenticationWizardStep from "../../AuthenticationWizardStep";
import {
  selectAuthWizardForm,
  selectAuthWizardLoading,
  selectFormErrors,
} from "../../../../selectors/appSelectors";
import {
  handleChangeAuthWizardForm,
  handleKeyDown,
  handleSubmitAuthWizardForm,
} from "../../utils";
import ProfileForm from "../../../ProfileForm/ProfileForm";
import ProfileCheckTermsForm from "../../../ProfileCheckTermsForm/ProfileCheckTermsForm";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

const AuthByEmailCompleteProfileForm = () => {
  const navigate = useNavigate();

  const form = useSelector(selectAuthWizardForm);
  const loading = useSelector(selectAuthWizardLoading);
  const formErrors = useSelector(selectFormErrors);

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  React.useEffect(() => {
    const newUrl = window.location.href.replace(window.location.search, "");
    window.history.pushState({}, null, newUrl);
  }, []);

  return (
    <AuthenticationWizardStep
      title={"Už jenom krůček!"}
      subtitle={"Dokončete svoji registraci."}
    >
      <TextField
        fullWidth
        margin="normal"
        disabled={true}
        label="E-mailová adresa"
        name={"login"}
        value={form.login}
        onChange={handleChangeAuthWizardForm}
        error={formErrors.hasOwnProperty("login")}
        helperText={
          formErrors.hasOwnProperty("login") ? formErrors["login"] : ""
        }
      />
      <TextField
        fullWidth
        margin="normal"
        autoFocus={true}
        type={showPassword ? "text" : "password"}
        label={"Heslo"}
        name={"password"}
        value={form.password}
        onChange={handleChangeAuthWizardForm}
        error={formErrors.hasOwnProperty("password")}
        helperText={
          formErrors.hasOwnProperty("password")
            ? formErrors["password"]
            : "Heslo musí mít minimálně 8 znaků"
        }
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          )
        }}
      />
      <TextField
        fullWidth
        margin="normal"
        type={showPassword ? "text" : "password"}
        label={"Heslo znovu"}
        name={"password2"}
        value={form.password2}
        onChange={handleChangeAuthWizardForm}
        onKeyPress={(event) => {
          handleKeyDown(event, { navigate });
        }}
        error={formErrors.hasOwnProperty("password2")}
        helperText={
          formErrors.hasOwnProperty("password2")
            ? formErrors["password2"]
            : ""
        }
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          )
        }}
      />

      <ProfileForm />
      <ProfileCheckTermsForm />

      <FormControl fullWidth margin="normal">
        <LoadingButton
          disabled={loading}
          loading={loading}
          sx={{ mt: 4 }}
          size={"large"}
          variant={"contained"}
          onClick={() => {
            handleSubmitAuthWizardForm({ navigate });
          }}
        >
          Dokončit registraci
        </LoadingButton>
      </FormControl>
    </AuthenticationWizardStep>
  );
};

export default AuthByEmailCompleteProfileForm;
