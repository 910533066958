import React from "react";
import { useSelector } from "react-redux";
import {
  selectAuthWizardMethod,
  selectAuthWizardStep,
  selectBrand,
} from "../../selectors/appSelectors";
import { AUTH_STEPS } from "./constants";

const AuthenticationWizard = () => {
  const brand = useSelector(selectBrand);
  const authWizardMethod = useSelector(selectAuthWizardMethod);
  const authWizardStep = useSelector(selectAuthWizardStep);

  return (
    <>
      {React.createElement(AUTH_STEPS[authWizardStep])}
    </>
  );
};

export default AuthenticationWizard;
