import React from "react";
import { useSelector } from "react-redux";
import { selectProfile } from "../../../selectors/appSelectors";
import { callApiForProfilePage } from "../utils";
import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  CircularProgress,
  Grid,
  Typography,
} from "@mui/material";
import { formatDate } from "../../../utils/datetime";
import { formatPrice } from "../../../utils/price";
import MediaDownloadToGooglePayButton from "../../MediaDownloadButton/MediaDownloadToGooglePayButton";
import MediaDownloadToAppleWalletButton from "../../MediaDownloadButton/MediaDownloadToAppleWalletButton";
import ProfileLoading from '../../ProfileLoading/ProfileLoading';

const valueType2Label = {
  0: " Kč",
  1: " %",
  4: "",
};

const MyVouchers = () => {
  const profile = useSelector(selectProfile);

  const [loading, setLoading] = React.useState(false);
  const [vouchers, setVouchers] = React.useState([]);

  const prepareVouchers = (arr) => {
    return arr
      .filter((a) => a.status === 1)
      .sort((a, b) => {
        return (
          new Date(b.validTill).getTime() - new Date(a.validTill).getTime()
        );
      });
  };

  const load = (profile) => {
    callApiForProfilePage({
      url: "/airme-proxy/PAVouchers/GetUserVouchers/" + profile.userNumber,
      setLoading,
      onSuccess: (r) => {
        if (r.status === 200) {
          // console.log(r.data[0].vouchers);
          setVouchers(prepareVouchers(r.data[0].vouchers));
        }
      },
    });
  };

  React.useEffect(() => {
    if (!!profile) {
      load(profile);
    }
  }, [profile]);

  const getVoucherAttribute = (voucher, attributeName) => {
    const attributes = voucher.attributes;
    const index = attributes.findIndex((a) => {
      return a.name === attributeName;
    });

    if (index > 0) {
      return attributes[index].value;
    }

    return null;
  };

  if (loading) {
    return <ProfileLoading />;
  }

  if (vouchers.length === 0) {
    return <Box><em>Momentálně není žádný poukaz k dispozici.</em></Box>;
  }

  return (
    <>
      <Grid container spacing={4}>

        {vouchers.map((voucher) => {
          return (
            <Grid item sm={6}>
              <Card key={voucher.voucherID} variant="outlined">
                {!!getVoucherAttribute(voucher, "ImageURL") && (
                  <CardMedia
                    component="img"
                    height="130"
                    src={getVoucherAttribute(voucher, "ImageURL")}
                  />
                )}
                <CardContent sx={{ bgcolor: "#fff" }}>
                  <Box
                    sx={{
                      textAlign: "center",
                      fontSize: 20,
                      fontWeight: 700,
                    }}
                  >
                    {voucher.template.name}
                  </Box>
                  <Box
                    sx={{
                      textAlign: "center",
                      fontSize: 50,
                      fontWeight: 900,
                    }}
                  >
                    {formatPrice(voucher.value)}{" "}
                    {valueType2Label[voucher.template.valueType]}
                  </Box>
                  <Box
                    sx={{
                      textAlign: "center",
                    }}
                  >
                    {voucher.template.description}
                  </Box>
                  <Box
                    sx={{
                      pt: 2,
                      textAlign: "center",
                      fontSize: 10,
                      fontWeight: 100,
                    }}
                  >
                    Platnost do {formatDate(voucher.validTill)}
                  </Box>
                </CardContent>
                <CardActions sx={{ alignItems: "flex-start" }}>
                  <MediaDownloadToAppleWalletButton
                    profile={profile}
                    type={"VOUCHER"}
                    voucher={voucher}
                  />
                  <MediaDownloadToGooglePayButton
                    profile={profile}
                    type={"VOUCHER"}
                    voucher={voucher}
                  />
                </CardActions>
              </Card>
            </Grid>
          );
        })}


      </Grid>
    </>
  );
};

export default MyVouchers;
