import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectAuthWizardForm,
  selectAuthWizardLoading,
  selectBrand,
  selectFormErrors,
} from "../../../../selectors/appSelectors";
import { FormControl, TextField } from "@mui/material";
import {
  handleKeyDown,
  handleChangeAuthWizardForm,
  handleSubmitAuthWizardForm,
} from "../../utils";
import LoadingButton from "@mui/lab/LoadingButton";
import AuthenticationWizardStep from "../../AuthenticationWizardStep";
import { setAuthWizardForm } from "../../../../actions/appActions";
import { initialAuthWizard } from "../../../../reducers/appReducer";

const AuthByPosStart = () => {
  const dispatch = useDispatch();

  const brand = useSelector(selectBrand);
  const form = useSelector(selectAuthWizardForm);
  const loading = useSelector(selectAuthWizardLoading);
  const formErrors = useSelector(selectFormErrors);

  React.useEffect(() => {
    dispatch(setAuthWizardForm({ ...initialAuthWizard.form }));
  }, []);

  return (
    <AuthenticationWizardStep
      title={"Zadejte e-mail zákazníka a klikněte na tlačítko Pokračovat"}
      subtitle={"Zadáním e-mailu bude ověřeno, zda-li u nás zákazník již má, nebo ještě nemá věrnostní účet"}
    >
      <TextField
        fullWidth
        margin="normal"
        id="email"
        label="E-mailová adresa zákazníka"
        autoFocus={true}
        placeholder="@"
        name={"login"}
        value={form.login}
        onChange={handleChangeAuthWizardForm}
        onKeyPress={(event) => {
          handleKeyDown(event, null);
        }}
        error={formErrors.hasOwnProperty("login")}
        helperText={
          formErrors.hasOwnProperty("login") ? formErrors["login"] : ""
        }
      />

      <FormControl fullWidth margin="normal">
        <LoadingButton
          disabled={loading}
          loading={loading}
          size={"large"}
          variant={"contained"}
          onClick={handleSubmitAuthWizardForm}
        >
          Pokračovat
        </LoadingButton>
      </FormControl>
    </AuthenticationWizardStep>
  );
};

export default AuthByPosStart;
