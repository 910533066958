export const validationRuleFnIsNotBlank = (value) => {
  return String(value).length > 0;
};
export const validationRuleFnIsInLength =
  (min = 0, max = 999) =>
  (value) => {
    return String(value).length >= min && String(value).length <= 999;
  };
export const validationRuleFnIsSameAs = (sameAs) => (value) => {
  return String(value) === String(sameAs);
};
export const validationRuleFnIsTrue = (value) => {
  return value === true;
};
export const validationRuleFnIsEmail = (value) => {
  return String(value)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export const validationValidateForm = (validationSchema, data) => {
  let isValid = true;
  let errors = {};
  Object.keys(validationSchema).forEach((key) => {
    const value = data[key];
    validationSchema[key].forEach((rule) => {
      if (!rule.ruleFn(value)) {
        isValid = false;
        if (!errors.hasOwnProperty(key)) {
          errors[key] = rule.message;
        }
      }
    });
  });

  return { isValid, errors };
};
