import {
  ADD_NOTIFICATION,
  REMOVE_NOTIFICATION,
  SET_AUTH_WIZARD,
  SET_FORM_ERRORS,
  SET_PROFILE,
  SET_PROFILE_FORM_FIELD,
  SET_VIEW,
  SET_WEBSITE,
} from "../constants/actionTypes";
import produce from "immer";

export const initialAuthWizard = {
  step: "AUTH_BY_EMAIL_STEP_LOGIN_FORM",
  method: "EMAIL",
  nextStep: "add_virtual_card",
  form: {
    login: "",
    password: "",
    password2: "",
    forgottenPasswordToken: "",
    cardType: "VIRTUAL",
    cardNumber: "",
  },
  loading: false,
  checkedUser: {
    login: null,
    exists: false,
    loyalty: false,
    userNumber: null,
  },
};

const initialState = {
  notifications: {},
  standalone: true,
  brandThemeName: null,
  view: "/",
  viewHistory: ["/"],
  authWizard: { ...initialAuthWizard },
  profile: null,
  formErrors: {},
  profileForm: {
    firstName: "",
    lastName: "",
    sex: 0,
    birthday: null,
    addressStreet: "",
    addressStreetNumber: "",
    addressCity: "",
    addressPostCode: "",
    checkVOP: false,
    checkNewsletter: false,
  },
};

export const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_NOTIFICATION: {
      return produce(state, (draft) => {
        const n = action.payload.notification;
        draft.notifications[n.id] = n;
      });
    }
    case REMOVE_NOTIFICATION: {
      return produce(state, (draft) => {
        const n = action.payload.notification;
        delete draft.notifications[n.id];
      });
    }
    case SET_WEBSITE: {
      return produce(state, (draft) => {
        draft.standalone = action.payload.standalone;
        draft.brandThemeName = action.payload.brandThemeName;
      });
    }
    case SET_VIEW: {
      return produce(state, (draft) => {
        if (action.payload.view === -1) {
          draft.view = draft.viewHistory[draft.viewHistory.length - 2];
          draft.viewHistory.pop();
        } else {
          draft.view = action.payload.view;
          draft.viewHistory.push(action.payload.view);
        }
      });
    }
    case SET_AUTH_WIZARD: {
      return produce(state, (draft) => {
        draft.authWizard = {
          ...draft.authWizard,
          ...action.payload.authWizard,
        };
      });
    }
    case SET_FORM_ERRORS: {
      return produce(state, (draft) => {
        draft.formErrors = action.payload.formErrors;
      });
    }
    case SET_PROFILE_FORM_FIELD: {
      return produce(state, (draft) => {
        draft.profileForm[action.payload.name] = action.payload.value;
      });
    }
    case SET_PROFILE: {
      return produce(state, (draft) => {
        draft.profile = action.payload.profile;
        draft.profileForm = action.payload.profileForm;
      });
    }
    default: {
      return state;
    }
  }
};
