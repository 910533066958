import { DEV_LOG_REQUEST, DEV_LOG_RESPONSE } from "./devConstants";
import produce from "immer";

const initialState = {
  apiLog: [],
};

const devReducer = (state = initialState, action) => {
  switch (action.type) {
    case DEV_LOG_REQUEST: {
      return produce(state, (draft) => {
        draft.apiLog.push({
          request: {
            created: action.payload.created,
            method: action.payload.request.method,
            url: action.payload.request.url,
          },
          response: null,
        });
      });
    }
    case DEV_LOG_RESPONSE: {
      return produce(state, (draft) => {
        const index = draft.apiLog.findIndex((l) => {
          return (
            !l.response &&
            l.request.method === action.payload.response.config.method &&
            l.request.url === action.payload.response.config.url
          );
        });
        if (index > -1) {
          draft.apiLog[index] = {
            ...draft.apiLog[index],
            response: {
              created: action.payload.created,
              status: action.payload.response.status,
              data: action.payload.response.data,
            },
          };
        }
      });
    }
    default: {
      return state;
    }
  }
};

export default devReducer;
