import React from "react";
import { buildUrlToDownloadCard } from "../../utils/mediaDownload";
import axios from "../../api/axios";
import { getAuthorizationByName } from "../../utils/auth";
import { LOCAL_STORAGE_AUTH_TOKEN_WEBSITE } from "../../utils/localStorage";
import { Box, ButtonBase, CircularProgress } from "@mui/material";

const MediaDownloadToGooglePayButton = (props) => {
  const { profile, type, card, voucher } = props;

  const [loading, setLoading] = React.useState(false);

  const handleClick = () => {
    setLoading(true);

    const buildConfig = {
      encryption: false,
      walletType: "GOOGLE",
      tenantId: 2,
      mediaType: type === "CARD" ? "MemberCard" : voucher.template.name,
      profile: profile,
    };
    if (type === "CARD") {
      buildConfig.cardId = card.tagId;
    }
    if (type === "VOUCHER") {
      buildConfig.voucherId = voucher.voucherID;
    }

    let url = buildUrlToDownloadCard(buildConfig);

    axios
      .request({
        method: "GET",
        url: process.env.REACT_APP_SERVER_URL + "/airme-proxy" + url,
        responseType: "json", // important
        headers: {
          Authorization: getAuthorizationByName(
            LOCAL_STORAGE_AUTH_TOKEN_WEBSITE
          ),
        },
      })
      .then((response) => {
        if (response.status === 200) {
          window.open(response.data[0].url, "_blank");
        }
      })
      .catch((e) => {
        //console.log(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <ButtonBase disabled={loading} onClick={handleClick}>
      {loading ? (
        <CircularProgress sx={{ position: "absolute", color: "#fff" }} />
      ) : (
        <></>
      )}
      <Box width={"100%"} height={"auto"} component="img" src={process.env.REACT_APP_URL + "/images/button-google-pay.png"} alt="Google Pay" />
    </ButtonBase>
  );
};

export default MediaDownloadToGooglePayButton;
