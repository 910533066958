import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectAuthWizardForm,
  selectAuthWizardLoading,
  selectAuthWizardNextStep,
  selectFormErrors,
} from "../../../../selectors/appSelectors";
import {
  Box,
  FormControl,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { handleSubmitAuthWizardForm } from "../../utils";
import LoadingButton from "@mui/lab/LoadingButton";
import AuthenticationWizardStep from "../../AuthenticationWizardStep";
import { setAuthWizardNextStep } from "../../../../actions/appActions";

const AuthByPosCustomerExistsAndIsLoyal = () => {
  const dispatch = useDispatch();
  const form = useSelector(selectAuthWizardForm);
  const loading = useSelector(selectAuthWizardLoading);
  const formErrors = useSelector(selectFormErrors);
  const nextStep = useSelector(selectAuthWizardNextStep);

  const handleChange = (event, newValue) => {
    dispatch(setAuthWizardNextStep(newValue));
  };

  const nextSteps = [
    {
      code: "add_virtual_card",
      label: "Přidělit novou digitální kartu zákazníkovi",
    },
    // {
    //   code: "add_physical_card",
    //   label: "Přidělit novou plastovou kartu zákazníkovi",
    // },
    { code: "change_login", label: "Zadat jinou e-mailovou adresu" },
    { code: "reset_password_request", label: "Obnovit heslo zákazníka" },
  ];

  React.useEffect(() => {
    dispatch(setAuthWizardNextStep("add_virtual_card"));
  }, []);

  return (
    <AuthenticationWizardStep
      title={
        <>
          Zákazník <strong>{form.login}</strong>
        </>
      }
      subtitle={
        <>
          Zákazník dle zadané e-mailové adresy již{" "}
          <strong style={{ color: "red", textTransform: "uppercase" }}>
            existuje
          </strong>{" "}
          a nelze jej tedy znovu založit. Co chcete nyní udělat?
        </>
      }
    >
      <Box sx={{ my: 2 }}>
        <ToggleButtonGroup
          fullWidth={true}
          color={"primary"}
          value={nextStep}
          exclusive
          onChange={handleChange}
        >
          {nextSteps.map((step) => {
            return (
              <ToggleButton key={step.code} value={step.code}>
                <strong>{step.label}</strong>
              </ToggleButton>
            );
          })}
        </ToggleButtonGroup>
      </Box>
      <FormControl fullWidth margin="normal">
        <LoadingButton
          disabled={
            loading || !nextSteps.map((step) => step.code).includes(nextStep)
          }
          loading={loading}
          size={"large"}
          variant={"contained"}
          onClick={handleSubmitAuthWizardForm}
        >
          Pokračovat
        </LoadingButton>
      </FormControl>
    </AuthenticationWizardStep>
  );
};

export default AuthByPosCustomerExistsAndIsLoyal;
