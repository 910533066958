import {
  ADD_NOTIFICATION,
  REMOVE_NOTIFICATION,
  SET_AUTH_WIZARD,
  SET_FORM_ERRORS,
  SET_PROFILE,
  SET_PROFILE_FORM_FIELD,
  SET_VIEW,
  SET_WEBSITE,
} from "../constants/actionTypes";
import { initialAuthWizard } from "../reducers/appReducer";
import { transformProfile2ProfileForm } from "../utils/dataTransformers";

export const setWebsite =
  ({ standalone, brandThemeName }) =>
  (dispatch) => {
    dispatch({
      type: SET_WEBSITE,
      payload: {
        standalone,
        brandThemeName,
      },
    });
  };

export const setView = (view) => (dispatch) => {
  dispatch({
    type: SET_VIEW,
    payload: {
      view,
    },
  });
};

export const setAuthWizardMethod = (method) => (dispatch) => {
  dispatch({
    type: SET_AUTH_WIZARD,
    payload: {
      authWizard: {
        method: method,
      },
    },
  });
};

export const setAuthWizardStep = (authWizardStep) => (dispatch) => {
  dispatch({
    type: SET_AUTH_WIZARD,
    payload: {
      authWizard: {
        step: authWizardStep,
      },
    },
  });
};

export const setAuthWizardNextStep = (nextStep) => (dispatch) => {
  dispatch({
    type: SET_AUTH_WIZARD,
    payload: {
      authWizard: {
        nextStep: nextStep,
      },
    },
  });
};

export const setAuthWizardCheckedUser = (checkedUser) => (dispatch) => {
  dispatch({
    type: SET_AUTH_WIZARD,
    payload: {
      authWizard: {
        checkedUser: checkedUser,
      },
    },
  });
};

export const setAuthWizardForm = (form) => (dispatch) => {
  dispatch({
    type: SET_AUTH_WIZARD,
    payload: {
      authWizard: {
        form: form,
      },
    },
  });
};

export const setAuthWizardLoading = (loading) => (dispatch) => {
  dispatch({
    type: SET_AUTH_WIZARD,
    payload: {
      authWizard: {
        loading: loading,
      },
    },
  });
};

export const resetAuthWizard = () => (dispatch) => {
  dispatch({
    type: SET_AUTH_WIZARD,
    payload: {
      authWizard: { ...initialAuthWizard },
    },
  });
};

export const setFormErrors = (formErrors) => (dispatch) => {
  dispatch({
    type: SET_FORM_ERRORS,
    payload: {
      formErrors,
    },
  });
};

export const setProfileFormField =
  ({ name, value }) =>
  (dispatch) => {
    dispatch({
      type: SET_PROFILE_FORM_FIELD,
      payload: {
        name,
        value,
      },
    });
  };

export const addNotification =
  ({ message, severity }) =>
  (dispatch) => {
    const dt = new Date();
    dispatch({
      type: ADD_NOTIFICATION,
      payload: {
        notification: {
          id: dt.getTime(),
          message,
          severity,
        },
      },
    });
  };

export const removeNotification =
  ({ id }) =>
  (dispatch) => {
    dispatch({
      type: REMOVE_NOTIFICATION,
      payload: {
        notification: {
          id,
        },
      },
    });
  };

export const setProfile =
  ({ profile }) =>
  (dispatch) => {
    dispatch({
      type: SET_PROFILE,
      payload: {
        profile,
        profileForm: transformProfile2ProfileForm({ profile }),
      },
    });
  };
