import React from "react";
import AuthenticationWizardStep from "../../AuthenticationWizardStep";

const AuthByEmailForgottenPasswordThankYouForm = () => {
  return (
    <AuthenticationWizardStep
      title={"Chci se registrovat!"}
      subtitle={"Na zadaný e-mail jsme poslali pokyny k dokončení registrace."}
    >
      <></>
    </AuthenticationWizardStep>
  );
};

export default AuthByEmailForgottenPasswordThankYouForm;
