import { BRANDS } from "../constants/brands";
import { createSelector } from "@reduxjs/toolkit";

export const selectBrandThemeName = (state) => state.app.brandThemeName;
export const selectBrand = (state) => {
  const brandThemeName = state.app.brandThemeName;
  if (BRANDS.hasOwnProperty(brandThemeName)) {
    return BRANDS[brandThemeName];
  }

  return null;
};
export const selectStandalone = (state) => state.app.standalone;
export const selectView = (state) => state.app.view;
export const selectViewHistory = (state) => state.app.viewHistory;
export const selectAuthWizardMethod = (state) => state.app.authWizard.method;
export const selectAuthWizardStep = (state) => state.app.authWizard.step;
export const selectAuthWizardNextStep = (state) =>
  state.app.authWizard.nextStep;
export const selectAuthWizardCheckedUser = (state) =>
  state.app.authWizard.checkedUser;
export const selectAuthWizardForm = (state) => state.app.authWizard.form;
export const selectAuthWizardLoading = (state) => state.app.authWizard.loading;

export const selectFormErrors = (state) => state.app.formErrors;

export const selectNotifications = (state) => state.app.notifications;

export const selectProfileForm = (state) => state.app.profileForm;
export const selectProfile = (state) => state.app.profile;

export const selectProfileFormField = createSelector(
  [
    selectProfileForm,
    (state, args) => {
      return args.name;
    },
  ],
  (profileForm, name) => {
    return profileForm[name];
  }
);

export const selectFormFieldError = createSelector(
  [selectFormErrors, (state, args) => args.name],
  (formErrors, name) => {
    return formErrors.hasOwnProperty(name) ? formErrors[name] : null;
  }
);

export const selectFormFieldIsRequired = createSelector(
  [selectProfileForm, selectBrand, (state, args) => args.name],
  (profileForm, brand, name) => {
    return brand.profileForm.validationSchema.hasOwnProperty(name);
  }
);
