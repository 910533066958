import React from "react";
import ProfileForm from "../../ProfileForm/ProfileForm";
import ProfileCheckTermsForm from "../../ProfileCheckTermsForm/ProfileCheckTermsForm";
import LoadingButton from "@mui/lab/LoadingButton";
import { Alert, FormControl, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  selectAuthWizardLoading,
  selectBrand,
  selectProfile,
  selectProfileForm,
} from "../../../selectors/appSelectors";
import axios from "../../../api/axios";
import { getAuthorizationByName } from "../../../utils/auth";
import { LOCAL_STORAGE_AUTH_TOKEN_WEBSITE } from "../../../utils/localStorage";
import {
  buildProfile,
  buildProfileOptions,
} from "../../../utils/dataTransformers";
import {
  setAuthWizardLoading,
  setFormErrors,
} from "../../../actions/appActions";
import { validationValidateForm } from "../../../utils/validation";
import { getValidationSchemaForBrand } from "../../AuthenticationWizard/validationSchemas";
import { AUTH_BY_EMAIL_STEP_COMPLETE_PROFILE_FORM } from "../../AuthenticationWizard/constants";
import ProfileDeleteButton from "../../ProfileDeleteButton/ProfileDeleteButton";

const MyProfile = (props) => {
  const { upsert } = props;

  const dispatch = useDispatch();
  const loading = useSelector(selectAuthWizardLoading);
  const profile = useSelector(selectProfile);
  const profileForm = useSelector(selectProfileForm);
  const brand = useSelector(selectBrand);

  const [formMessage, setFormMessage] = React.useState(null);

  const handleSubmit = () => {
    setFormMessage(null);
    dispatch(setFormErrors({}));

    const { isValid: isValid2 = true, errors: errors2 = {} } =
      validationValidateForm(
        getValidationSchemaForBrand(
          AUTH_BY_EMAIL_STEP_COMPLETE_PROFILE_FORM,
          profileForm,
          brand
        ),
        profileForm
      );

    if (!isValid2) {
      dispatch(setFormErrors({ ...errors2 }));
    }

    if (isValid2) {
      dispatch(setAuthWizardLoading(true));

      const data = buildProfile({
        login: profile.login,
        profileForm,
        options: buildProfileOptions({ profileForm }),
      });

      upsert(
        data,
        () => {
          setFormMessage({
            type: "success",
            message: "Změny byly uloženy",
          });
        },
        () => {
          setFormMessage({
            type: "error",
            message: "Změny nebyly uloženy",
          });
        },
        () => {
          dispatch(setAuthWizardLoading(false));
        }
      );
    }
  };

  return (
    <>
      <ProfileForm />
      <ProfileCheckTermsForm />
      {!!formMessage && (
        <Alert severity={formMessage.type}>{formMessage.message}</Alert>
      )}
      <FormControl fullWidth margin="normal">
        <LoadingButton
          disabled={loading}
          loading={loading}
          sx={{ mt: 4 }}
          size={"large"}
          variant={"contained"}
          onClick={() => {
            handleSubmit();
          }}
        >
          Uložit změny
        </LoadingButton>
      </FormControl>
      <FormControl margin="normal">
        <ProfileDeleteButton upsert={upsert} />
      </FormControl>
    </>
  );
};

export default MyProfile;
