import React from "react";
import onScan from "onscan.js";
import { TextField } from "@mui/material";
import { useSelector } from "react-redux";
import {
  selectAuthWizardForm,
  selectAuthWizardLoading,
  selectFormErrors,
} from "../../../../../selectors/appSelectors";
import { handleChangeAuthWizardForm } from "../../../utils";

const TextFieldCardNumber = () => {
  const form = useSelector(selectAuthWizardForm);
  const loading = useSelector(selectAuthWizardLoading);
  const formErrors = useSelector(selectFormErrors);

  const handleScan = (event) => {
    let scannedCode = event.detail.scanCode;

    if (scannedCode.length === 9) {
      scannedCode =
        scannedCode.substring(0, 3) +
        "-" +
        scannedCode.substring(3, 6) +
        "-" +
        scannedCode.substring(6, 9);
    }

    handleChangeAuthWizardForm({
      target: {
        name: "cardNumber",
        value: scannedCode,
      },
    });
  };

  React.useEffect(() => {
    // Enable scan events for the entire document
    onScan.attachTo(window.document);
    // Register event listener
    window.document.addEventListener("scan", function(sScancode, iQuantity) {
      handleScan(sScancode);
    });

    return () => {
      onScan.detachFrom(window.document);
    };
  }, []);

  return (
    <TextField
      fullWidth
      margin="normal"
      label={"Číslo plastové karty"}
      autoFocus={true}
      name={"cardNumber"}
      value={form.cardNumber}
      onChange={handleChangeAuthWizardForm}
      error={formErrors.hasOwnProperty("cardNumber")}
      helperText={
        formErrors.hasOwnProperty("cardNumber") ? formErrors["cardNumber"] : ""
      }
    />
  );
};

export default TextFieldCardNumber;
