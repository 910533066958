import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectNotifications } from "../../selectors/appSelectors";
import { useSnackbar } from "notistack";
import { removeNotification } from "../../actions/appActions";

let displayed = [];

const Notifier = () => {
  const dispatch = useDispatch();
  const notifications = useSelector(selectNotifications);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const storeDisplayed = (id) => {
    displayed = [...displayed, id];
  };

  const removeDisplayed = (id) => {
    displayed = [...displayed.filter((key) => id !== key)];
  };

  React.useEffect(() => {
    Object.keys(notifications).forEach((key) => {
      const n = notifications[key];

      // do nothing if snackbar is already displayed
      if (displayed.includes(n.id)) return;

      // display snackbar using notistack
      enqueueSnackbar(n.message, {
        key: n.id,
        variant: n.severity,
        onExited: (event, id) => {
          // remove this snackbar from redux store
          dispatch(removeNotification({ id }));
          removeDisplayed(id);
        },
      });

      // keep track of snackbars that we've displayed
      storeDisplayed(n.id);
    });
  }, [notifications, closeSnackbar, enqueueSnackbar, dispatch]);

  return <></>;
};

export default Notifier;
