import AuthByEmailForm from "./authenticationSteps/byEmail/AuthByEmailForm";
import AuthByEmailLoginForm from "./authenticationSteps/byEmail/AuthByEmailLoginForm";
import AuthByEmailCompleteProfileForm from "./authenticationSteps/byEmail/AuthByEmailCompleteProfileForm";
import AuthByEmailSigninForm from "./authenticationSteps/byEmail/AuthByEmailSigninForm";
import AuthByEmailForgottenPasswordRequestForm from "./authenticationSteps/byEmail/AuthByEmailForgottenPasswordRequestForm";
import AuthByEmailForgottenPasswordThankYouForm from "./authenticationSteps/byEmail/AuthByEmailForgottenPasswordThankYouForm";
import AuthByEmailForgottenPasswordResetForm from "./authenticationSteps/byEmail/AuthByEmailForgottenPasswordResetForm";
import AuthByEmailForgottenPasswordResetedForm from "./authenticationSteps/byEmail/AuthByEmailForgottenPasswordResetedForm";
import AuthByEmailSigninThankYouForm from "./authenticationSteps/byEmail/AuthByEmailSigninThankYouForm";
import AuthByPosStart from "./authenticationSteps/byPos/AuthByPosStart";
import AuthByPosCustomerExistsAndIsLoyal from "./authenticationSteps/byPos/AuthByPosCustomerExistsAndIsLoyal";
import AuthByPosCustomerExistsAndIsNotLoyal from "./authenticationSteps/byPos/AuthByPosCustomerExistsAndIsNotLoyal";
import AuthByPosCustomerDoesNotExist from "./authenticationSteps/byPos/AuthByPosCustomerDoesNotExist";
import AuthByPosForgottenPasswordResetRequested from "./authenticationSteps/byPos/AuthByPosForgottenPasswordResetRequested";
import AuthByPosPhysicalCardRequest from "./authenticationSteps/byPos/AuthByPosPhysicalCardRequest";
import AuthByPosPhysicalCardRequested from "./authenticationSteps/byPos/AuthByPosPhysicalCardRequested";
import AuthByPosCustomerCreated from "./authenticationSteps/byPos/AuthByPosCustomerCreated";
import AuthByPosVirtualCardRequested from "./authenticationSteps/byPos/AuthByPosVirtualCardRequested";
import AuthByEmailChangeEmailExecutionForm from "./authenticationSteps/byEmail/AuthByEmailChangeEmailExecutionForm";

export const AUTH_BY_EMAIL_STEP_FORM = "AUTH_BY_EMAIL_STEP_FORM";
export const AUTH_BY_EMAIL_STEP_LOGIN_FORM = "AUTH_BY_EMAIL_STEP_LOGIN_FORM";
export const AUTH_BY_EMAIL_STEP_COMPLETE_PROFILE_FORM =
  "AUTH_BY_EMAIL_STEP_COMPLETE_PROFILE_FORM";
export const AUTH_BY_EMAIL_STEP_SIGNIN_FORM = "AUTH_BY_EMAIL_STEP_SIGNIN_FORM";
export const AUTH_BY_EMAIL_STEP_SIGNIN_THANK_YOU_FORM =
  "AUTH_BY_EMAIL_STEP_SIGNIN_THANK_YOU_FORM";
export const AUTH_BY_EMAIL_STEP_FORGOTTEN_PASSWORD_REQUEST_FORM =
  "AUTH_BY_EMAIL_STEP_FORGOTTEN_PASSWORD_REQUEST_FORM";
export const AUTH_BY_EMAIL_STEP_FORGOTTEN_PASSWORD_THANK_YOU_FORM =
  "AUTH_BY_EMAIL_STEP_FORGOTTEN_PASSWORD_THANK_YOU_FORM";
export const AUTH_BY_EMAIL_STEP_FORGOTTEN_PASSWORD_RESET_FORM =
  "AUTH_BY_EMAIL_STEP_FORGOTTEN_PASSWORD_RESET_FORM";
export const AUTH_BY_EMAIL_STEP_FORGOTTEN_PASSWORD_RESETED_FORM =
  "AUTH_BY_EMAIL_STEP_FORGOTTEN_PASSWORD_RESETED_FORM";

export const AUTH_BY_EMAIL_STEP_CHANGE_EMAIL_EXECUTION_FORM =
  "AUTH_BY_EMAIL_STEP_CHANGE_EMAIL_EXECUTION_FORM";

export const AUTH_BY_POS_START = "AUTH_BY_POS_START";
export const AUTH_BY_POS_CUSTOMER_EXISTS_AND_LOYAL =
  "AUTH_BY_POS_CUSTOMER_EXISTS_AND_LOYAL";
export const AUTH_BY_POS_CUSTOMER_EXISTS_AND_NOT_LOYAL =
  "AUTH_BY_POS_CUSTOMER_EXISTS_AND_NOT_LOYAL";
export const AUTH_BY_POS_CUSTOMER_NOT_EXISTS =
  "AUTH_BY_POS_CUSTOMER_NOT_EXISTS";
export const AUTH_BY_POS_FORGOTTEN_PASSWORD_RESET_REQUESTED =
  "AUTH_BY_POS_FORGOTTEN_PASSWORD_RESET_REQUESTED";
export const AUTH_BY_POS_PHYSICAL_CARD_REQUEST =
  "AUTH_BY_POS_PHYSICAL_CARD_REQUEST";
export const AUTH_BY_POS_PHYSICAL_CARD_REQUESTED =
  "AUTH_BY_POS_PHYSICAL_CARD_REQUESTED";
export const AUTH_BY_POS_VIRTUAL_CARD_REQUESTED =
  "AUTH_BY_POS_VIRTUAL_CARD_REQUESTED";
export const AUTH_BY_POS_CUSTOMER_CREATED = "AUTH_BY_POS_CUSTOMER_CREATED";

export const AUTH_STEPS = {
  [AUTH_BY_EMAIL_STEP_FORM]: AuthByEmailForm,
  [AUTH_BY_EMAIL_STEP_LOGIN_FORM]: AuthByEmailLoginForm,
  [AUTH_BY_EMAIL_STEP_COMPLETE_PROFILE_FORM]: AuthByEmailCompleteProfileForm,
  [AUTH_BY_EMAIL_STEP_SIGNIN_FORM]: AuthByEmailSigninForm,
  [AUTH_BY_EMAIL_STEP_SIGNIN_THANK_YOU_FORM]: AuthByEmailSigninThankYouForm,
  [AUTH_BY_EMAIL_STEP_FORGOTTEN_PASSWORD_REQUEST_FORM]:
    AuthByEmailForgottenPasswordRequestForm,
  [AUTH_BY_EMAIL_STEP_FORGOTTEN_PASSWORD_THANK_YOU_FORM]:
    AuthByEmailForgottenPasswordThankYouForm,
  [AUTH_BY_EMAIL_STEP_FORGOTTEN_PASSWORD_RESET_FORM]:
    AuthByEmailForgottenPasswordResetForm,
  [AUTH_BY_EMAIL_STEP_FORGOTTEN_PASSWORD_RESETED_FORM]:
    AuthByEmailForgottenPasswordResetedForm,
  [AUTH_BY_EMAIL_STEP_CHANGE_EMAIL_EXECUTION_FORM]:
  AuthByEmailChangeEmailExecutionForm,
  [AUTH_BY_POS_START]: AuthByPosStart,
  [AUTH_BY_POS_CUSTOMER_EXISTS_AND_LOYAL]: AuthByPosCustomerExistsAndIsLoyal,
  [AUTH_BY_POS_CUSTOMER_EXISTS_AND_NOT_LOYAL]:
    AuthByPosCustomerExistsAndIsNotLoyal,
  [AUTH_BY_POS_CUSTOMER_NOT_EXISTS]: AuthByPosCustomerDoesNotExist,
  [AUTH_BY_POS_FORGOTTEN_PASSWORD_RESET_REQUESTED]:
    AuthByPosForgottenPasswordResetRequested,
  [AUTH_BY_POS_PHYSICAL_CARD_REQUEST]: AuthByPosPhysicalCardRequest,
  [AUTH_BY_POS_PHYSICAL_CARD_REQUESTED]: AuthByPosPhysicalCardRequested,
  [AUTH_BY_POS_VIRTUAL_CARD_REQUESTED]: AuthByPosVirtualCardRequested,
  [AUTH_BY_POS_CUSTOMER_CREATED]: AuthByPosCustomerCreated,
};
